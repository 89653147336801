.MuiAvatar-root img {
    width: auto;
    /* height: 2rem; */
    /* background-color: red; */
    height: 2rem;
    border-radius: 2rem;
}
.SelectSystems {
    /* background-color: red; */
    width: 100%;
}
li.Option {
    padding: 0 0.5rem;
    align-items: center;
}
li.Option .MuiGrid-container{
    align-items: center;
}
.Container {
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    margin: 0.5rem 0;
    padding: 0.5rem;
}

.Container .MuiGrid-container {
    align-items: center;
}

.ContainerRow {
    align-items: center;
    border-bottom: 1px dotted #ccc;
    padding: 0.25rem 0;
}

.Pod {
    display: inline-flex;
    align-items: center;
    border-radius: 1rem;
    overflow: hidden;
    /* height: 2rem; */
    padding: 0.125rem;
    margin: 0.125rem 0;
    font-size: 0.75rem;
}

.Pod .MuiGrid-root {
    display: flex;
    align-items: center;
}
.Modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    background-color: white;
    border: 1px solid #000;
    border-radius: 8px;
    box-shadow: 1rem;
    padding: 1rem;
    max-height: 400px;
    overflow: auto;
}

.Match {
    justify-content: flex-start;
    align-items: center;
    display: flex;
}
.Match .MatchButton {
    font-weight: bold;
}
.Match .Matches {
    max-height: 10rem;
    overflow-y: auto;
}
.Match .MuiGrid-container{
    align-items: center;
}
.Match .MuiGrid-item,
.Match .MuiButton-root {
    /* width: 100%; */
}
.Match .MatchButtonCount,
.Match .MatchButtonCount .MuiSvgIcon-root  {
    margin-right: 0.75rem;
    display: flex;
}
.SystemIcons .MuiToggleButton-root {
    padding: 0.5rem;
}
.SystemIcons .MuiToggleButton-root.MuiDisabled {
    border: none;
}
.SystemIcons .MuiDisabled .MuiAvatar-img {
    opacity: 0.3;
}
.Title,
.Title a,
.Title input {
    font-size: 1.5rem;
    font-weight: bold;
    /* margin-left: 0rem; */
}

.Title .AvatarSystem .MuiAvatar-root {
    height: 36px;
    width: 36px;
}

.Title .AvatarSystem .MuiBadge-badge {
    top: 32px;
}
.Title .TitleRow a {
    font-size: 0.875rem;
    font-weight: normal;
}

.TitleLink {
    display: flex;
    align-items: center;
}

.TitleLink .MuiTypography-root {
    padding-left: 0.5rem;
}

.TitleLink .MuiAvatar-root,
.AvatarSystem .MuiAvatar-root {
    height: 24px;
    width: 24px;
    /* background: white; */
    background: none;
    margin: 0;
}

.AvatarSystem .MuiBadge-badge {
    background: rgba(255, 255, 255, 0.8);
    height: 20px;
    width: 16px;
    right: 2px;
    top: 22px;
}

.AvatarSystem .MuiSvgIcon-root {
    /* height: 1.75rem;
    width: 1.75rem; */
    height: 16px;
    width: 16px;
}

.ButtonSmall.MuiIconButton-root {
    padding: 0.125rem;
}

.BadgeButton .MuiIconButton-root {
    padding: 0.25rem;
}

.BadgeButton .MuiBadge-badge {
    /* background-color: plum; */
}